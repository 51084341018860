@import '../../styles/modules/rwd';

.quiz-modal {
  position: fixed;
  top: 20%;
  left: 10%;
  width: 80%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;

  &-full {
    top: 70px;
    left: 0;
    width: 100%;
    height: 90%;
  }

  .close-modal-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
  }

  .container {
    width: 100%;
    height: 100%;
    padding: 0 20px 20px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  .buttons {
    align-self: flex-end;
    width: 100%;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;

      button:first-of-type {
        margin-right: 10px;
      }

      button {
        margin: 0 10px;
      }

      button:last-of-type {
        margin-left: 10px;
      }

      div {
        z-index: 1;
      }
    }
  }
}
