.new-password {
  h2 {
    font-family: 'mainFontBold', sans-serif;
    font-size: 24px;
    line-height: 33px;
    margin: 65px 0 0;
  }

  h3 {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 45px;
  }

  .new-password-form {
    max-width: 450px;

    .btns-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 23px;
    }
  }
}
