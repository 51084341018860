.cookie-bar {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  box-shadow: 0 1px 15px 1px rgba(169, 169, 169, 0.2);

  .cookie-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      padding: 30px 0;
    }

    .btn-wrapper {
      margin-left: 30px;
    }
  }
}
