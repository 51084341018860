html, body {
  margin: 0;
  padding: 0;
  font-family: 'mainFontRegular', sans-serif;
  color: $mainFontColor;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
}

.main-container {
  max-width: 1260px;
  width: 90%;
  margin: 0 auto;
}

.small-container {
  max-width: 1050px;
  width: 85%;
  margin: 0 auto;
}

.display-block {
  display: block;
  z-index: 1000;
}

.display-none {
  display: none;
}

a {
  color: $mainFontColor;
  text-decoration: none;
}

.field {
  height: 50px;
}
