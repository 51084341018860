@import '../../styles/modules/rwd';

.nav-desktop-logged-in {
  display: flex;
  align-items: center;
  font-family: 'mainFontSemiBold', sans-serif;
  z-index: 1;

  @include breakpoint(md){
    display: none;
  }

  .btn-wrapper {
    position: relative;
    margin-left: 25px;
  }

  a {
    margin-left: 25px;
    font-size: 14px;
  }

  a[target='_blank'] {
    margin-left: 0;
  }

  .nav-item {
    display: inline-block;
    line-height: 71px;
    margin: 0;
    font-size: 14px;
    cursor: pointer;
  }

  h4 {
    display: inline-block;
    line-height: 71px;
    margin: 0 0 0 25px;
    font-size: 14px;
    cursor: pointer;
  }

  .nav-btn-container {
    margin-left: 25px;
  }
}

.nav-desktop-logged-out {
  display: flex;
  align-items: center;
  font-family: 'mainFontSemiBold', sans-serif;
  margin-right: 12px;
}

.mobile-nav-icon {
  display: flex;
  align-items: center;
  line-height: 71px;
  margin-right: 15px;
  z-index: 2000;
  cursor: pointer;
  @include breakpoint(md, min) {
    display: none;
  }
}

.mobile-nav-list {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: #FFF;
  z-index: 1000;
  margin: 71px 0 0 24px;
  padding-top: 40px;
  @include breakpoint(md, min) {
    display: none;
  }

  li {
    margin-left: 70px;
    list-style-type: none;
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 48px;
    font-family: 'mainFontSemiBold', sans-serif;
    cursor: pointer;
  }
}

