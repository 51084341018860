@import '../../styles/modules/rwd';

.basicInfoEditMode {
  max-width: 100%;

  .field {
    margin-bottom: 20px;
  }

  .field label, .field input {
    font-size: 13px;
  }

  .btns-container {
    display: flex;
    justify-content: space-around;
    margin: 24px 0;

    p {
      cursor: pointer;
    }

    button {
      height: 50px;

      h3 {
        margin: 0;
        font-size: 13px;
      }
    }
  }

}
