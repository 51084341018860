.footer-container {
  background-color: #1D2946;
  color: #FFF;

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-info-list {
      display: flex;
      width: 80%;
      justify-content: space-around;
      padding-top: 40px;

      h3 {
        font-size: 16px;
        margin: 0;
      }

      a {
        color: #FFF;
      }
    }

    span {
      margin-bottom: 40px;
    }
  }

}
