@font-face {
  font-family: 'mainFontRegular';
  src: url('../fonts/Open_Sans/OpenSans-Regular.ttf');
  font-weight: regular;
}

@font-face {
  font-family: mainFontExtraBold;
  src: url('../fonts/Open_Sans/OpenSans-ExtraBold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: mainFontSemiBold;
  src: url('../fonts/Open_Sans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: mainFontLight;
  src: url('../fonts/Open_Sans/OpenSans-Light.ttf');
}
