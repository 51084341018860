.login-section {
  padding: 0 75px;
  color: #000;
  background-color: #F2F2F2;
  min-width: 446px;

  form.login-form {
    display: grid;
    grid-gap: 15px;
    margin-top: 42px;

    .field label, .field input {
      font-size: 13px;
    }

    .field {
      height: 66px;

      p {
        margin: 0;
      }
    }

    .MuiInput-underline-36:after {
      border-bottom: #000;
    }

    .btns-container {
      display: grid;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .forgotten-password {
      text-decoration: underline;
      cursor: pointer;
      text-align: right;
    }
  }
}

