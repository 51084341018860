@import '../../styles/modules/rwd';

.org-panel-container {
  h1 {
    font-size: 20px;
    margin-top: 100px;
    @include breakpoint(xs){
      margin-top: 30px;
    }
  }

  .sub-header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 16px 0 60px;
    @include breakpoint(md) {
      flex-direction: column;
    }

    p {
      font-size: 20px;
      margin: 0;
      @include breakpoint(md) {
        align-self: flex-start;
        margin-bottom: 30px;
      }

      span {
        font-family: 'mainFontSemiBold', sans-serif;
      }
    }
  }

  h2 {
    font-size: 18px;
  }

  .person-id {
    text-align: center;
  }

  .table-container {
    margin-bottom: 20px;
  }
}
