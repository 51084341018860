input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0 1000px #FFF inset;
}

.password-icon-container {
  cursor: pointer;
}
