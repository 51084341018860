@import '../../styles/modules/rwd';
@import '../../styles/modules/variables';

.videos {
  .videos-header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 115px 0 135px;
    @include breakpoint(md) {
      flex-direction: column;
      margin: 72px 0 50px;
    }
    @include breakpoint(xs) {
      margin-top: 32px;
    }

    h2 {
      font-family: 'mainFontBold', sans-serif;
      margin: 0;
      font-size: 29px;
      width: 60%;
      @include breakpoint(md) {
        align-self: flex-start;
        width: 80%;
        margin-bottom: 35px;
      }
      @include breakpoint(xs) {
        width: 100%;
        margin-bottom: 25px;
        font-size: 25px;
      }
    }
  }

  .video-box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 30px;

    .video-box {
      cursor: pointer;
      position: relative;

      h3 {
        font-size: 15px;
        font-family: 'mainFontSemiBold', sans-serif;
        margin: 0;
      }

      p {
        font-size: 12px;
        margin-top: 6px;
      }

      .video-icon {
        position: absolute;
        top: 10px;
        right: 10px;

        &--quiz {
          color: $penzoBlue;
        }

        &--passed {
          color: $penzoGreen;
        }
      }
    }

    img {
      width: 100%;
    }
  }
}
