@import '../../styles/modules/rwd';

.video-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  .video-modal-window {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 85%;
    height: 55vw;
    max-height: 75%;
    transform: translate(-50%, -50%);

    .react-player {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
