.registration-section {
  color: #000;
  background-color: #F2F2F2;

  form.registration-form {
    display: grid;
    grid-gap: 5px;
    max-width: 500px;

    .field label, .field input {
      font-size: 13px;
    }

    .field {
      height: 66px;

      p {
        margin: 0;
      }
    }

    .checkbox-field {
      label {
        margin-left: 0;
        span:first-child {
          padding: 0 4px 0 0;
        }
      }

      .checkbox-error {
        color: #F00;
        font-size: 13px;
        margin-bottom: 12px;
      }
    }


    .btns-container {
      display: grid;
      margin-bottom: 24px;
    }
  }
}

.MuiInput-underline-36:after {
  border-bottom: 2px solid #000 !important;
}

