@import '../../styles/modules/variables';

.profile-section {
  color: #000;
  max-width: 450px;

  h3 {
    font-size: 24px;
    line-height: 33px;
    font-family: 'mainFontBold', sans-serif;
    margin-top: 63px;
  }

  h4 {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 50px;
  }

  .profile-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;

    h5 {
      font-size: 15px;
      line-height: 20px;
      margin: 0 60px 0 0;
    }

    span {
      font-size: 13px;
      text-decoration: underline;
      cursor: pointer;
    }

    p {
      font-size: 13px;
      line-height: 18px;
    }
  }

  .email {
    margin: 0 60px 0 0;
    font-size: 13px;
  }

  .logout {
    display: block;
    text-align: right;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.app-version {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.7rem;
  text-align: center;
  color: $mainFontColor;
}
