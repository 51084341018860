.forgotten-pass-container {
  background-color: #F2F2F2;

  .password-field {
    margin-bottom: 50px;
  }

  .btns-container {
    margin-bottom: 25px;
    display: flex;

    h3 {
      margin: 0;
      font-size: 13px;
    }

    span {
      line-height: 36px;
      margin: 0 45px 0 0;
      cursor: pointer;
    }
  }
}
