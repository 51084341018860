@import '../../styles/modules/rwd';

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  .modal-window {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    max-width: 450px;
    padding: 0 75px;
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    align-items: center;

    .close-modal-btn {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      margin: 20px 20px 0 0;
    }

    h3 {
      font-family: 'mainFontBold', sans-serif;
      font-size: 24px;
      line-height: 33px;
      margin: 25px 0 12px;
    }

    p {
      width: 80%;
      text-align: center;
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 20px;
    }

    .btns-container {
      margin-bottom: 25px;
      height: 36px;
      display: flex;

      h3 {
        margin: 0;
        font-size: 13px;
      }

      h5 {
        line-height: 36px;
        margin: 0 45px 0 0;
        cursor: pointer;
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #F2F2F2 inset !important;
  }
}
