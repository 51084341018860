.training-container {
  height: 385px;

  .training-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .training-description {
      margin-top: 25px;

      h3 {
        font-family: 'mainFontSemiBold', sans-serif;
        font-size: 25px;
        line-height: 34px;
      }

      p {
        margin-top: 25px;
        font-size: 15px;
        line-height: 20px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;

      li {
        font-size: 14px;
        line-height: 40px;
      }
    }

    .training-pricing {
      margin-top: 250px;

      h5 {
        text-align: center;
      }
    }
  }
}

