$xxs: 480px;
$xs: 640px;
$sm: 768px;
$md: 991px;
$lg: 1270px;
$xlg: 1690px;
$xxlg: 2200px;

@mixin breakpoint($class, $size: 'max') {
  $type: #{$size}-width;

  @if $class == xxs {
    @media ($type: $xxs) { @content; }
  }

  @else if $class == xs {
    @media ($type: $xs) { @content; }
  }

  @else if $class == sm {
    @media ($type: $sm) { @content; }
  }

  @else if $class == md {
    @media ($type: $md) { @content; }
  }

  @else if $class == lg {
    @media ($type: $lg) { @content; }
  }

  @else if $class == xlg {
    @media ($type: $xlg) { @content; }
  }

  @else if $class == xxlg {
    @media ($type: $xxlg) { @content; }
  }

  @else {
    @warn 'Breakpoint mixin supports: xxs, xs, sm, md, lg, xlg, xxlg';
  }
}
