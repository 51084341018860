.not-found-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-family: 'mainFontBold', sans-serif;
    font-size: 40px;
    line-height: 37px;
    margin-bottom: 0;
  }

  p {
    font-size: 30px;
  }
}
