@import '../../styles/modules/rwd';

.activate {
  color: #000;

  h2 {
    font-size: 24px;
    font-family: 'mainFontSemiBold', sans-serif;
    margin: 65px 0 70px;
    @include breakpoint(xxs){
      margin: 17px 0 48px;
    }
  }

  form.activation-form {
    display: grid;
    grid-gap: 5px;
    max-width: 500px;

    .field label, .field input {
      font-size: 13px;
    }

    .field {
      height: 66px;

      p {
        margin: 0;
      }
    }

    .checkbox-field {
      label {
        margin-left: 0;
        span:first-child {
          padding: 0 4px 0 0;
        }
      }

      .checkbox-error {
        color: #F00;
        font-size: 13px;
        margin-bottom: 12px;
      }
    }


    .btns-container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;
    }
  }
}
