@import '../../styles/modules/rwd';

.login {
  color: #000;
  max-width: 450px;

  h2 {
    font-size: 24px;
    margin: 45px 0 75px;
    @include breakpoint(xxs) {
      margin: 100px 0 50px;
    }
  }

  form.login-form {
    display: grid;
    grid-gap: 15px;
    margin-top: 42px;

    .field label, .field input {
      font-size: 13px;
    }

    .field {
      height: 66px;

      p {
        margin: 0;
      }
    }

    .btns-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .forgotten-password {
      text-decoration: underline;
      cursor: pointer;
      text-align: right;
    }
  }
}

