@import '../../styles/modules/rwd';

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #FFF;
  box-shadow: 0 1px 15px 1px rgba(169, 169, 169, 0.2);
  z-index: 1;

  .header-logo {
    width: 163px;
  }

  .header-container {
    display: flex;
    justify-content: space-between;

    .logo-container {
      display: flex;
      align-items: center;
      height: 71px;
    }
  }
}
