.btn-wrapper {
  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(169, 169, 169, 0.5);
    background-color: #FFF;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    font-family: 'mainFontSemiBold', sans-serif;
    font-size: 14px;
    white-space: nowrap;
    transition: all 0.5s ease-in-out;

    &:focus {
      outline: #F2F2F2 solid 2px;
    }

    &:hover {
      box-shadow: 0 2px 6px 2px rgba(169, 169, 169, 0.5);
    }

    &.btn-type {
      &-medium {
        padding: 0 30px;
        height: 36px;
      }

      &-large {
        padding: 0 70px;
        height: 40px;
      }
    }

    &.btn-theme {
      &-dark {
        color: #FFF;
        background-color: #000;

        &:focus {
          outline: #F2F2F2 solid 2px;
        }

        &:hover {
          box-shadow: 0 2px 8px 2px rgba(169, 169, 169, 0.8);
        }
      }
    }
  }
}

