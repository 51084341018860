.free-content-container {
  margin: 120px auto 0;

  h3 {
    font-family: 'mainFontBold', sans-serif;
    font-size: 25px;
    line-height: 34px;
    margin: 0 0 12px;
  }

  p {
    max-width: 60%;
    margin: 0 0 0 60px;
    line-height: 20px;
  }

  .free-videos-container {
    margin-top: 50px;
    margin-bottom: 115px;
    display: flex;
    justify-content: space-between;

    iframe {
      width: 316px;
      height: 184px;
      display: block;
    }

    h5 {
      font-size: 12px;
      line-height: 19px;
      margin: 12px 0 0;
    }
  }
}
