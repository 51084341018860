.confirm-modal-section {
  min-width: 330px;
  padding: 0 130px;
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;

  h2 {
    text-align: center;
    font-size: 24px;
    font-family: 'mainFontBold', sans-serif;
    line-height: 32px;
    margin-top: 24px;
  }

  p {
    font-size: 13px;
    text-align: center;
    line-height: 18px;
  }

  button {
    margin: 36px 0 24px;
  }
}
