.hero-section {
  margin-top: 90px;
  display: flex;

  iframe {
    min-width: 633px;
    height: 384px;
  }

  .hero-text-container {
    margin-left: 60px;
    height: 384px;
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(3, 1fr);

    h4 {
      font-family: 'mainFontBold', sans-serif;
      grid-row-start: 3;
      font-size: 24px;
      line-height: 32px;
      max-width: 95%;
      margin: 0;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    p {
      font-size: 18px;
      grid-row-start: 4;
      line-height: 24px;
      max-width: 95%;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .start-container {
      display: flex;
      align-items: center;
      grid-row-start: grid-row-end;

      svg {
        margin-right: 70px;
      }
    }
  }
}
